import * as React from 'react';

function NoMatch() {
    return (
        <div style={{ padding: 20 }}>
            <h2>404: Page Not Found</h2>
            <p>Diese Seite existiert nicht.</p>
        </div>
    );
}

export default NoMatch;